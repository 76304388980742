/* You can add global styles to this file, and also import other style files */
@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.min.css';
@import 'primeflex/primeflex.scss';
@import 'primeicons/primeicons.css';
@import '_theme.scss';

/* Core CSS required for Ionic components to work properly */
@import 'node_modules/@ionic/angular/css/core.css';
// @import "node_modules/@ionic/angular/css/normalize.css";
@import 'node_modules/@ionic/angular/css/structure.css';

/* Optional CSS utils that can be commented out */
@import 'node_modules/@ionic/angular/css/padding.css';
@import 'node_modules/@ionic/angular/css/float-elements.css';
@import 'node_modules/@ionic/angular/css/text-alignment.css';
@import 'node_modules/@ionic/angular/css/text-transformation.css';
@import 'node_modules/@ionic/angular/css/flex-utils.css';
@import 'node_modules/@ionic/angular/css/display.css';

html {
  scroll-behavior: smooth;
  font-size: 80%;
}

body {
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  font-size: 16px;
  margin: 0;
}
